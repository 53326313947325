import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	MasterPageFeatureConfigSymbol,
	BrowserWindowSymbol,
	Props,
	BrowserWindow,
	IPropsStore,
	IPageDidMountHandler,
	IPageWillUnmountHandler,
	pageIdSym,
} from '@wix/thunderbolt-symbols'
import type {
	PageAnchorsHandlers,
	PageAnchorsModelUpdateConfig,
	PageAnchorsPageConfig,
	PageAnchorsSiteConfig,
} from './types'
import { name } from './symbols'
import { createAnchorObserver } from './pageAnchorsUtils'
import { IModelUpdatesHandlersImplementor } from 'ds-feature-model-updates-invoker'

/**
 * This is your feature.
 * You can get your configuration written in site-assets and viewer-model injected into your feature
 */
const pageAnchorsFactory = (
	pageFeatureConfig: PageAnchorsPageConfig,
	siteFeatureConfig: PageAnchorsSiteConfig,
	window: BrowserWindow,
	propsStore: IPropsStore,
	pageId: string
): IPageDidMountHandler & IPageWillUnmountHandler => {
	const pageAnchorsObservers = pageFeatureConfig.pageAnchorsObservers.concat(siteFeatureConfig.pageAnchorsObservers)
	const activeAnchorObservers = pageFeatureConfig.activeAnchorObservers.concat(
		siteFeatureConfig.activeAnchorObservers
	)
	const observeAnchors = createAnchorObserver(
		pageAnchorsObservers,
		activeAnchorObservers,
		pageFeatureConfig,
		siteFeatureConfig,
		window,
		propsStore,
		pageId
	)
	let observersCleanup: () => void | undefined

	return {
		pageDidMount(): void {
			if (pageAnchorsObservers.length || activeAnchorObservers.length) {
				observersCleanup = observeAnchors()
			}
		},
		pageWillUnmount(): void {
			if (observersCleanup) {
				observersCleanup()
			}
		},
	}
}

const editorPageAnchorsFactory = (
	window: BrowserWindow,
	propsStore: IPropsStore
): IModelUpdatesHandlersImplementor<PageAnchorsHandlers> => {
	let observersCleanup: () => void

	return {
		featureName: name,
		handlers: {
			onAnchorUpdate(pageUpdateConfig: PageAnchorsModelUpdateConfig) {
				const { pageConfig, siteConfig, pageId } = pageUpdateConfig
				// For menus which are rendered in the add panel as a mini site
				if (!pageId) {
					return
				}
				const pageAnchorsObservers = [
					...(pageConfig?.pageAnchorsObservers || []),
					...(siteConfig?.pageAnchorsObservers || []),
				]
				const activeAnchorObservers = [
					...(pageConfig?.activeAnchorObservers || []),
					...(siteConfig?.activeAnchorObservers || []),
				]
				const hasObservers = pageAnchorsObservers.length || activeAnchorObservers.length
				if (observersCleanup) {
					observersCleanup()
				}

				if (hasObservers) {
					const observeAnchors = createAnchorObserver(
						pageAnchorsObservers,
						activeAnchorObservers,
						pageConfig,
						siteConfig,
						window,
						propsStore,
						pageId,
						true
					)

					observersCleanup = observeAnchors()
				}
			},
		},
	}
}

export const PageAnchors = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(MasterPageFeatureConfigSymbol, name),
		BrowserWindowSymbol,
		Props,
		pageIdSym,
	],
	pageAnchorsFactory
)

export const EditorPageAnchors = withDependencies([BrowserWindowSymbol, Props], editorPageAnchorsFactory)
